



  .servicesmain h1{
 width:800px;
 color: rgb(0, 0, 0);
  }

  .servicesmain{
  background-image: url(../../assets/Services/service\ banner_3.jpg);
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: top center ;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .servicesmain::after{
    position: absolute;
    top: 0;
    right: 0;
    content: " ";
    background-color: rgba(0, 0, 0, 0.507);
    height: 100%;
    width: 100%;
   
  }

  .single-service{
    border: 1px solid grey;
    margin-bottom: 20px;

    box-shadow: 0 0 2px 1px grey;
  }


  .single-service h4{
font-size: 18px;
   font-weight: 700;
  }


  .single-service p{
    font-size: 14px;
  padding: 10px;
      }


.services-content{
  text-align: justify;
  margin-bottom: 10px;
}

.services-content h5 {
  font-size: 17px;
}

.services-content small {
  font-size: 15px;
  font-weight: 600;
}

.services-content li{

  list-style: inside;
  font-size: 14px;

}

.servicess{

border: 1px solid grey;
box-shadow: 0 0 2px 1px grey;
  padding: 20px;
}


.services-content img{
  width: 380px;
  height: 380px;
}

.serviceimage{
  justify-content: center;
  align-items: center;
  display: flex;
}

.serviceimage img {
  border-radius: 10px;
  height: 120px;
  object-fit: cover;
}

.servicess{
margin:20px 10px;
}
