

.main-banner-content {
  position: absolute;
  z-index: 11;
  color: rgb(255, 255, 255);
  left: 0;
  background-color: #ffffff;
  padding: 15px;
  opacity: 0.5;
  max-width: 600px;
  color: black;
}


.main-banner-content h1{


  z-index: 11;
  color: rgb(255, 255, 255);
  left: 0;
  background-color: #000000;
  padding: 10px;
  opacity: 1;
}

.main-banner-content p {
  z-index: 11;
  color: rgb(255, 255, 255);
  left: 0;
  background-color: #FFF;
  padding: 5px;
  opacity: 1;
  color: black;
}


.main-banner {
  background-image: url(../../assets/mainpage/newbanner.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-banner-contentt{
  background-image: url(../../assets/mainpage/image2.jpg);
  background-repeat: no-repeat;
background-size: cover;
background-position: center center;
}



.main-banner-center{
  position: absolute;
  top: 40%;
  z-index: 11;
  text-align: center;
  left: 16%;
  background-color: #000000;
  padding: 25px;
}

.main-banner-center h1{
  color: white;
}

.main-banner-center p{
  color: white;
}


.about-row{  background-color: #eee;
margin-top: 30px;

margin:50px auto;
}



.about-section1 h5{

  color: #476239;
 
}

.about-section1 h6{

  color: #f4c142;
 
}


.about-section1 p{

 font-weight: 600;
 
}


.about-section2 p{

  font-weight: 600;
  
 }
 

 .about-section1 img{
  height: 200px;
  width: 200px;
  object-fit: cover;
  background-color: white;
  border-radius: 50%;
text-align: center;
 }

 .about-section2 h5{

  color: #476239;
 
}

.about-section2 h6{

  color: #f4c142;
 
}


 .about-section2 img{
  height: 200px;
  background-color: white;
  width: 200px;
  object-fit: cover;
  border-radius: 50%;
 }

 .info-text{
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
 }


.main-banner-center button{
  color: black;
  background-color: #7fa99b;
  padding: 8px 25px;
  border-radius: none;
  border: none;
  outline: none;
  font-weight: 600;
}

.service-button {
text-align: center;
margin-top: 50px;

}

.services-section-content img {
   height: 200px; 
  object-fit: cover;
}

.service-button a{
border: 1px solid black;
  text-decoration: none;
  padding: 8px 15px;
  color: #ffffff;
  background-color: #476239;
  font-weight: 600;
  border: none;
  }


.main-banner-content button{
  width:  140px;
  padding: 8px;
  background-color: transparent;
  color: white;
  outline: white;
  border-color: white;
  font-weight: 600px;
  box-shadow: inset 0 0 0 0 white;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  font-weight: 600;
}

.main-banner-content button:hover{


    color: black;
    font-weight: 600px;
    transition: all 0.4s ease-in-out;
    box-shadow: inset 140px 0 0 0 white;

  }

.contact-strip{
  background-color: #476239;
color: white ;
font-weight: 600;
padding: 10px;
font-size: 30px;
}

.contact-strip span{
color: #f4c142;
font-weight: 700;
}

.services-main{
padding-top: 30px;
padding-bottom: 30px;
}

.services-section{
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-top: 30px;
  box-shadow: 0px 0px 2px 1px rgb(176, 175, 175);
  background-color: #eee;

}

.services-section-content{
  padding: 0px 0px;
  border: 1px solid grey;


  box-shadow: 0 0 2px 1px grey;
}

.services-section-content h5 {
  /* padding: 15px 0px 0 19px; */
  padding: 15px;
}
.services-section-content p{
  padding: 10px 0px 0 10px;
}


.services-section-content a{
  padding: 10px 20px;
  border: none;
}


.testimonial-main{
  background-color: #f1b963;
  padding: 30px;

}

.testimonial{
  background-color: white;
  padding: 30px;
  height: 250px;
}

.testimonial p{
  font-size: 14px;
  font-weight: 600;
  }


.testimonial h4{
font-size: 15px;
font-weight: 600;
}

.errormessage{
  color: red;
}

/*
---------------------------------------------Contact---------------------------- */

/* color:  red#476239, black#212d31, grey#343a40, white#eee  */

@media (max-width:991px){
  .info-text {

    align-items: center;


}

.services-section-content img {
  height: 255px;
  object-fit: cover;
}

.testimonial {
  background-color: white;
  padding: 30px;
  height: 150px;
  margin: 30px;
}
}

@media only screen and (max-width:767px){
  .about-section1{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .services-content {
    margin-bottom: 10px !important;
    text-align: center !important ;
}

  .about-section2{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.contain {
  background-color: #eee;
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  margin: 50px 0;
}

div.form {
  background-color: #eee;
}
.contact-wrapper {
  margin: auto 0;
}

.submit-btn {
  float: left;
}
.reset-btn {
  float: right;
}

.form-headline:after {
  content: "";
  display: block;
  width: 10%;
  padding-top: 10px;
  border-bottom: 3px solid #476239;
}

.highlight-text {
  color: #476239;
}

.hightlight-contact-info {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
}

.highlight-text-grey {
  font-weight: 500;
}

.email-info {
    margin-top: 20px;
}

::-webkit-input-placeholder { /* Chrome */
  font-family: 'Roboto', sans-serif;
}

.required-input {
  color: black;
}
@media (min-width: 600px) {
  .contain {
    padding: 0;
  }






}


@media (max-width: 600px) {
 
  .testimonial {
    background-color: white;
    padding: 30px;
    height: 250px;
    margin: 30px;
  }




}


h3,
ul {
  margin: 0;
}

h3 {
  margin-bottom: 1rem;
}

.form-input:focus,
textarea:focus{
  outline: 1.5px solid #476239;
}

.form-input,
textarea {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
}

.wrapper > * {
  padding: 1em;
}

@media (min-width: 700px) {
  .wrapper {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
  .wrapper > * {
    padding: 2em 2em;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.contacts {
  color: #212d31;
}

.form {
  background: #fff;
}

.formm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
form label {
  display: block;
}
form p {
  margin: 0;
}

.full-width {
  grid-column: 1 / 3;
}

button,
.submit-btn,
.form-input,
textarea {
  padding: 1em;
}

button, .submit-btn {
  background: transparent;
  border: 1px solid #476239;
  color: #476239;
  padding: 5px 20px;
  text-transform: uppercase;
  width: 120px;
}

button:hover, .submit-btn:hover,
button:focus , .submit-btn:focus{
  background: #476239;
  outline: 0;
  color: #eee;
}
.error {
  color: #476239;
}


@media (max-width:567px){
  .main-banner-content {

    width: 230px;
}

.contact-strip {
  background-color: #476239;
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  padding: 10px;
}

.services-section-content img {
   height: 100%; 
  object-fit: cover;
}

}