.footerr{
    display: flex;
      justify-content: center;
    align-items: center;
    
}

.footer-footer{
    color:ghostwhite;
    background-color: #22313f;
    font-size: 12px;
    padding: 5px 0;
}

.footer-quicklinnks a{
    text-decoration: none;
    color: white;
}

.footer-quicklinnks a:hover{
margin-left: 10px;
transition: all 0.4s ease-in-out;
}

.footer-quicklinnks li{
    list-style: none;

}

.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}