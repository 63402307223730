.about-banner{
    background-color: #008bd0;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.about-banner h1{
  color: #FFF;
  max-width: 800px;
  margin: auto;
}

.about-content{
    margin-top: 30px;
    margin-bottom: 30px;
}

.about-mission{
    background-color: #008bd0;
    color: #FFF;
    padding: 30px;
    text-align: center;
}

.about .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {

    background-color: #008bd0;
    color: white;
    font-weight: 600;
}

.about .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
  background-color: #008bd0;
  color: white;
  font-weight: 600;
}

