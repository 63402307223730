@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');


.contact-mainn {
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(https://images.pexels.com/photos/325185/pexels-photo-325185.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940);
  background-size: cover;
  background-position: center;
  position: relative;
  color: white;
}

.contact-mainn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  color: white;
}



.section-header {
  margin-bottom: 50px;
  margin-top: 100px;
  text-align: center;
  z-index: 99;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
  z-index: 99;
}

.section-header p {
  color: #FFF;
  z-index: 99;
}

.contact-row  {
  display: flex;
  flwx-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  width: 50%;
  z-index: 99;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
  z-index: 99;
}

.contact-info-icon {
  height: 50px;
  width: 50px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  z-index: 99;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
  z-index: 99;
}

.contact-info-content {
  margin-left: 20px;
  z-index: 99;
}

.contact-info-content h4 {
  color: #1da9c0;
  font-size: 1.4em;
  margin-bottom: 5px;
  z-index: 99;
}

.contact-info-content p {
  color: #FFF;
  font-size: 1em;
  z-index: 99;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
  z-index: 99;
  margin-bottom: 35px;
}

.contact-button{
  background-color:     #1da9c0;
  padding: 8px;
  color: white;
 width: 100px;
 max-width: 100px;
  border: none;
  outline: none;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
  
}




.contact-form .input-box input[type="submit"]
{
  width: 100%;
  background: #00bcd4;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  border: 1px solid #00bcd4;
  transition: 0.5s;

}

.contact-form .input-box input[type="submit"]:hover
{
  background: #FFF;
  color: #00bcd4;
}

@media (max-width: 991px) {
  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .contact-row {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}


/* Your existing CSS styles ... */

.input-box.active span {
  display: none;
}

/* Additional styles to adjust the look of the input box when the value is entered */
.input-box.active {
  border-color: #1da9c0;
}

/* ... Other styles ... */

.error{
  color: red;
  display: block;
  font-size: 13px;
  font-weight: 600;
}