.App{
    overflow-x: hidden;
}

.navbar-expand-lg.show .navbar-expand-lg{
    background-color: black;
}

.alldisplayflex{
    display: flex;
    justify-content: center;
    align-items: center;
}