.main-logo img {
  width: 100px;
}

body{ margin: 0;}

#header{
position: relative;
background: transparent;
position: fixed;
top: 0;
right: 0;

left: 0;
margin: 0;
z-index: 111;

}

.header-center-menu{
text-align: center;
display: flex;
justify-content: center;
align-items: center;
gap: 30px;
}

.header-center-menu a{
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
}

.header-end-menu{
    text-align: right;
    font-weight: 500;
}

.header-end-menu p{
  margin: 0;
    font-size: 20px;
    color: #ffffff;
  }

.header-end-menu span{
  color: #f4c142;
  font-size: 25px;
}

.header.onscroll {
  background-color: #ffffff !important;
  height: 90px;
  z-index: 1000;
transition: all 0.4s;
}

.header.onscroll a {
 color: black;
}

.header.onscroll .header-end-menu p{

    color: #000000;
  }

  @media(max-width:991px){
    .header-end-menu{
      display: none;
    }
  }

  @media(max-width:567px){
 
  }